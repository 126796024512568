import { useState } from "react";
import style from "./note.module.css";

export default function Note({ notes, onChange, disabled }) {
  const [noteAdd, setNoteAdd] = useState("");
  const [type, setType] = useState("bug");

  const handleNoteAddChange = (e) => setNoteAdd(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);

  const add = () => {
    var _notes = [...notes];
    _notes.push({
      type,
      note: noteAdd.trim(),
    });
    onChange(_notes);
    setNoteAdd("");
  };

  return (
    <div className={style.container}>
      <select
        className={style.type}
        value={type}
        onChange={handleTypeChange}
        disabled={disabled}
      >
        <option value="bug">Bug</option>
        <option value="change">Changed</option>
        <option value="new_feature">New Feature</option>
      </select>
      <textarea
        disabled={disabled}
        value={noteAdd}
        className={style.add_note}
        onChange={handleNoteAddChange}
      ></textarea>
      <button
        onClick={add}
        disabled={noteAdd.trim().length < 1 || disabled}
        className={style.add}
      >
        Add
      </button>
    </div>
  );
}
