import style from "./sidebar.module.css";
import { Link, useLocation } from "react-router-dom";

const links = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Update Application",
    url: "/update-application",
  },
  {
    name: "Server Disk Space",
    url: "/server-disk-space",
  },
];

export default function Sidebar() {
  const location = useLocation();
  return (
    <div className={style.container}>
      <div className={style.links}>
        {links.map((link, index) => (
          <Link
            className={style.link}
            key={index}
            to={link.url}
            active={location.pathname == link.url ? 1 : 0}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
