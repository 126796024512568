import logo from './logo.svg';
import Header from './Header'
import style from './app.module.css'
import Sidebar from './Sidebar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import UpdateApplication from './UpdateApplication'
import ServerDiskSpace from './ServerDiskSpace'

function App() {
  return (
    <>
      <Header />
      <div className={style.body}>
        <Router>
          <Sidebar />
          <div className={style.content}>
            <Routes>
              <Route path='/' element={<>Selamat datang</>} />
              <Route path='/update-application' element={<UpdateApplication />} />
              <Route path='/server-disk-space' element={<ServerDiskSpace />} />
            </Routes>
          </div>
        </Router>
      </div>
    </>
  );
}

export default App;
