import axios from "axios";
import config from "config";
import { useEffect, useState } from "react";
import style from "./updates.module.css";
import moment from "moment-timezone";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export default function Updates({ product, onDeleted }) {
  const [results, setResults] = useState([]);

  const fetchProduct = (product) => {
    const url = `${config.api_host}/updates?build_number=0&product=${product}`;
    axios.get(url).then(({ data }) => {
      if (data.error) {
        window.alert(data.message);
      } else {
        setResults(data);
      }
    });
  };

  useEffect(() => {
    if (typeof product === "string") {
      fetchProduct(product);
    }
  }, [product]);

  const viewChanges = (json) => {
    const data = JSON.parse(json);
    const msg = data.map((x) => `* [${x.type}]: ${x.note}\n`);
    window.alert(msg);
  };

  const deleteUpdate = (id) => {
    const confirm = window.confirm("Apakah anda yakin ingin melanjutkan ?");
    if (!confirm) return;
    const args = { id };
    const url = `${config.api_host}/update/delete`;
    axios
      .post(url, args)
      .then(({ data }) => {
        window.alert(data.message);
        if (!data.error) {
          onDeleted();
          fetchProduct();
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  };

  if (results.length < 1) {
    return <>Tidak ada data</>;
  } else {
    return (
      <table className={style.data_table}>
        <thead>
          <tr>
            <td>No</td>
            <td>Product</td>
            <td>Updated at</td>
            <td>Build Number</td>
            <td>Build Version</td>
            <td>Size</td>
            <td>File Name</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {results.map((row, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{row.product_name}</td>
              <td>
                {moment(row.uploaded * 1000)
                  .tz("Asia/Jakarta")
                  .format("DD MMMM YYYY HH.mm")}
              </td>
              <td>{row.build_number}</td>
              <td>{row.build_version_str}</td>
              <td>{formatBytes(row.size)}</td>
              <td>
                <a
                  href={
                    config.api_host +
                    "/update/dl/" +
                    row.zip_file +
                    "?as=" +
                    encodeURIComponent(row.file_name)
                  }
                  target="_blank"
                >
                  {row.file_name}
                </a>
              </td>
              <td>
                <div className={style.action}>
                  <button
                    onClick={() => {
                      deleteUpdate(row.id);
                    }}
                  >
                    Hapus
                  </button>
                  <button onClick={() => viewChanges(row.notes)}>
                    Change's
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
