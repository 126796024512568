import style from "./header.module.css";

export default function Header() {
  return (
    <div className={style.container}>
      <div className={style.app}>
        <span className={style.name}>Ziqva Labs Developer</span>
      </div>
    </div>
  );
}
