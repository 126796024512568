import { useEffect, useState } from "react";
import config from "config";
import axios from "axios";

export default function ServerDiskSpace() {
  const [free, setFree] = useState("0 B");
  const [total, setTotal] = useState("0 B");
  useEffect(() => {
    const url = `${config.api_host}/disk/space`;
    axios
      .get(url)
      .then(({ data }) => {
        if (data.error) {
          window.alert(data.message);
        } else {
          const res = data.data;
          setFree(res.free);
          setTotal(res.total_size);
        }
      })
      .catch((err) => {
        window.alert(err.message);
      });
  }, []);

  return (
    <div>
      <span>Server Disk Space</span>
      <div>
        <tr>
          <td>Free: {free.str}</td>
        </tr>
        <tr>
          <td>Total: {total.str}</td>
        </tr>
      </div>
    </div>
  );
}
